import React from "react"
import { graphql } from "gatsby"

import { Sections as Page } from "../components/Sections/Sections"

export const query = graphql`
  query {
    page: sanityPageHomepage {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      content: _rawContent(resolveReferences: { maxDepth: 6 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
